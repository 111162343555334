<template>
  <header>
    <b-navbar>
      <template #brand>
        <div class="logo">
          <!-- <router-link to="/"><span>i</span> - ASSISTANT</router-link> -->
          <a href="/">KABINET</a>
          <b-button
            outlined
            class="mobile-search"
            v-if="!showWorkspace"
            @click="$store.commit('toggleSidebar')"
          >
            <b-icon
              pack="fas"
              icon="search"
              size="is-medium"
              type="is-primary"
            ></b-icon
          ></b-button>
        </div>
      </template>
      <template #end>
        <b-navbar-item href="/" v-if="showWorkspace">Поиск</b-navbar-item>
        <b-navbar-item href="/search-cadnumber" v-if="showSearchByCadNumber"
          >Поиск по кадастровому номеру</b-navbar-item
        >
        <b-navbar-dropdown label="Профиль" right boxed>
          <b-navbar-item href="/user" v-if="!superAdmin">Профиль</b-navbar-item>
          <b-navbar-item href="/admin" v-if="superAdmin"
            >Админ панель</b-navbar-item
          >
          <b-navbar-item href="/statistic" v-if="!superAdmin"
            >Статистика</b-navbar-item
          >
          <b-navbar-item href="#" @click.prevent="logout">Выйти</b-navbar-item>
        </b-navbar-dropdown>
      </template>
    </b-navbar>
  </header>
</template>

<script>
export default {
  name: 'Header',
  computed: {
    user() {
      return this.$store.state.user;
    },
    showWorkspace() {
      return this.$route.name !== 'Workspace';
    },
    showSearchByCadNumber() {
      return this.$route.name !== 'SearchCadNumber';
    },
    role() {
      return this.user !== undefined ? this.user.role : '';
    },
    superAdmin() {
      return this.role === 1;
    },
  },
  methods: {
    async logout() {
      this.$store.commit('toggleLoader', true);
      await this.$store.dispatch('logout');
      this.$store.commit('toggleLoader', false);
      this.$router.push('/login');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

header {
  grid-area: header;
  position: sticky;
  top: 0;
  z-index: 9;
  width: 100%;
  -webkit-box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);

  .navbar {
    padding: 0 20px;
  }

  .logo {
    font-weight: lighter;
    font-size: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1px;
    color: #496ca2;

    span {
      font-weight: bold;
      font-size: 22px;
    }

    .mobile-search {
      display: none;
    }

    @media screen and (max-width: 960px) {
      .mobile-search {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
      }
    }
  }

  .polises-utils {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 50px;

    .tag-bold {
      font-weight: bold;
    }
  }
}
</style>
